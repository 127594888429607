<template>
    <v-row>
        <v-col
            v-for="(item, index) in items"
            :key="index"
            class="d-flex child-flex"
            cols="3"
        >
        <!-- <div @mouseover="mouseover(item.ids)" @mouseout="mouseout"> -->
        <div class="sound">
            <v-chip
                class="btn-sound"
                color="grey lighten-3 font-weight-bold black--text"
                dark
            >
                {{ item.name_cn }}
            </v-chip>

            <!-- <v-btn
                class="btn-del"
                fab
                elevation="2"
                x-small
                color="white"
                width="25"
                height="25"
                @click.stop="delCallback(item.rid)"
            >
                <v-icon color="grey darken-1"> mdi-close </v-icon>
            </v-btn> -->
            
        </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'pictureList',
    props: [
        'items'
    ],
    computed: {
        curImgId() {
            return this.curImg.imgs[this.curImg.imgIdx]
        }
    },
    data() {
        return {
            curImg: {
                id: '',
                imgs: [],
                imgIdx: 0,
            },
            intervalAutoSwitch: null,
        };
    },
    methods: {
        mouseover(itemId) {
            this.curImg.id = itemId[0]
            this.curImg.imgIdx = 0
            this.curImg.imgs = itemId

            if (this.curImg.imgs.length > 1) {
                this.curImg.imgIdx = 1
                this.intervalAutoSwitch = setInterval(this.autoSwitchImg, 600);
            }
        },
        mouseout() {
            this.curImg.id = ''
            this.curImg.imgIdx = 0
            this.curImg.imgs = []
            clearInterval(this.intervalAutoSwitch);
        },
        autoSwitchImg() {
            if (this.curImg.imgs.length > 1) {
                this.curImg.imgIdx++;
                if (this.curImg.imgIdx >= this.curImg.imgs.length) {
                    this.curImg.imgIdx = 0;
                }
            }
        },
        clickCallback(res) {
            this.$emit("clickCallback", res)
        },
        delCallback(rid) {
            this.$emit("delCallback", rid)
        }
    }
}
</script>

<style lang="scss" scoped>
.sound {
    width: 100%;
    position: relative;
}
.btn-sound {
    cursor: pointer;
    width: 100%;
    height: 40px;
    padding-left: 30px;
    border-radius: 4px;
}

.hand-over {
    cursor: pointer;
}
.btn-del {
    position: absolute;
    top: 7px;
    right: -10px;
    &:hover {
        background-color: #eee !important;
    }
}
</style>
